import React from 'react';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { Trans } from 'react-i18next';
import { AuthorInfo } from '@presentation/components/AuthorInfo';
import Button from '@mui/material/Button';
import { useMediaQuery } from '@mui/material';
import { type Theme, useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import type { CreatorModel } from '@domain/models';
import { LinkGeneratorService } from '@domain/services/LinkGeneratorService';
import { useSingularLinkRedirect } from '@presentation/hooks/useSingularLinkRedirect';

import {
	BackgroundImage,
	BannerImage,
	LeftColumn,
	Overlay,
	RightColumn,
	Container,
	Wrapper,
	BannerImageContainer,
	BannerTitle,
} from './styles';

export type ArtworkBannerProps = {
	isLoading: boolean;
	id: string;
	title: string;
	description: string;
	imageSrc: string;
	backgroundImageSrc: string;
	creator: CreatorModel | null;
};
export const ArtworkBanner = ({
	isLoading,
	id,
	title,
	description,
	imageSrc,
	backgroundImageSrc,
	creator,
}: ArtworkBannerProps) => {
	const { redirectToApp } = useSingularLinkRedirect(LinkGeneratorService.LINK_TYPES.PUZZLE, id);
	const navigate = useNavigate();
	const theme = useTheme();
	const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

	if (isLoading) {
		return (
			<Wrapper maxWidth='xl'>
				<Container maxWidth='xl' container columnSpacing={{ sm: 0, md: 4 }}>
					<Skeleton variant='rounded' height={200} animation='wave' />
				</Container>
			</Wrapper>
		);
	}

	return (
		<Wrapper maxWidth='xl'>
			<Container container columnSpacing={{ sm: 0, md: 4 }} bgimage={imageSrc} onClick={redirectToApp}>
				<Overlay />
				<BackgroundImage src={backgroundImageSrc} alt={title} />
				<LeftColumn item xs={12} sm={12} md={6} lg={8} display='flex' alignItems='center'>
					<Box>
						<Stack direction='row' spacing={3} alignItems='center' marginBottom={3}>
							{!isMobile && (
								<>
									<AuthorInfo creator={creator} noShowSubtitle onClick={() => navigate(`/${creator?.profileLink}`)} />
									<FiberManualRecordIcon sx={{ color: theme.colors.cardTextColor, fontSize: 12 }} fontSize='small' />
								</>
							)}
							<Typography variant='subtitle2'>
								<Trans i18nKey='general.dailyPuzzle' />
							</Typography>
							<FiberManualRecordIcon sx={{ color: theme.colors.cardTextColor, fontSize: 12 }} fontSize='small' />
							<Typography variant='subtitle2Uppercase'>{description}</Typography>
						</Stack>
						<Stack direction='row' spacing={3} alignItems='center'>
							{!isMobile && (
								<Button
									fullWidth={isMobile}
									style={{
										textTransform: 'none',
									}}
									color='buttonBlue'
									variant='contained'
									onClick={redirectToApp}
								>
									<Trans i18nKey='general.playInApp' />
								</Button>
							)}
							<BannerTitle variant='h1' marginBottom={6} marginTop={4}>
								{title}
							</BannerTitle>
						</Stack>
					</Box>
				</LeftColumn>
				<RightColumn
					item
					xs={12}
					sm={12}
					md={6}
					lg={4}
					display={{ xs: 'none', sm: 'none', md: 'flex' }}
					alignItems='center'
				>
					<BannerImageContainer>
						<BannerImage src={imageSrc} alt={title} />
					</BannerImageContainer>
				</RightColumn>
			</Container>
		</Wrapper>
	);
};
